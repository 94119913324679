.sidebar1 {
  width: 250px;
  height: 100vh; // Full-height sidebar
  background-color: #333; // Dark background for the sidebar
  color: white;
  .menu-item1 {
    padding: 20px;
    cursor: pointer;
    &:hover {
      background-color: #555; // Slightly lighter on hover
    }
    .submenu1 {
      display: block;

      .submenu-item1 {
        padding: 10px 20px;
        cursor: pointer;
        &:hover {
          background-color: #777; // Slightly lighter on hover
        }
      }
    }
  }
}
