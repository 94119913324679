/** @format */

.sb-profile-container {
  display: flex;
  padding: 28px;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--28, 28px);
  align-self: stretch;

  img.sb-pf-pic {
    height: 125px;
    width: 125px;
    border-radius: 50%;
  }
}

.exit-sb-pf {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
  span {
    font-size: 14px;
    cursor: pointer;
  }
}

.user-sb-info {
  display: flex;
  flex-direction: column;
  div {
    display: flex;
    flex-direction: row;
    gap: 4px;
    color: #1c1c1c66;
  }
  gap: 16px;
  & > div:nth-of-type(1) {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
  }
}
