/** @format */

// Card.scss

.office-cards-container {
  display: flex;
  margin-top: 60px;
  flex-wrap: wrap;
  gap: 24px;
  padding: 25px;
}
.ant-card-body {
  padding: 20px 0 0 0 !important;
}
.office-card {
  box-shadow: 0px 4px 25px -8px rgba(0, 0, 0, 0.25) !important;
  width: 300px;
  margin-right: 20px;
  text-align: center;
}

.card-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  .user-type {
    font-size: 20px;
    font-size: medium;
  }
}

.card-action {
  width: 100%;
  background-color: rgba(22, 119, 255, 0.12);
  height: 60px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-top: 10px;

  img {
    cursor: pointer;
  }
}
.card-view-button {
  background-color: rgba(118, 45, 132, 1);
  width: 102px;
  height: 31px;
  padding: 5px;
  margin: auto;
  cursor: pointer;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.card-wrapper {
  display: flex;
  cursor: pointer;

  min-width: 280px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 16px;
  background: #f7f9fb;
}
.company-heading {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 2px;
  :nth-child(1) {
    font-style: normal;
    font-weight: 600;
    color: black;
  }
  :nth-child(2) {
    color: rgba(28, 28, 28, 0.4);
    font-weight: 400;
  }
}

.card-goal-progress {
  display: flex;

  span {
    color: #8a8cd9;
    font-size: 12px;
    font-weight: 400;
  }
}

.progress-bar {
  width: 100%; // Full width of the container
  background-color: #e0e0e0; // Light grey background
  border-radius: 8px; // Rounded corners
  overflow: hidden; // Ensures the inner div doesn't spill out

  .progress {
    height: 4px; // Fixed height of the progress bar
    background-color: #76a9fa; // Example: a nice blue
    text-align: center; // Center text inside the progress bar
    color: white; // Text color
    line-height: 20px; // Center the text vertically
    transition: width 0.4s ease; // Smooth transition for width change
    background: #a1e3cb;

    &:before {
      content: attr(data-progress); // Display the width percentage
    }
  }
}

.card-goal-stats {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

.card-images {
  width: 100%;
  display: flex;
  justify-content: space-between;
  :nth-child(2) {
    img {
      width: 77px;
      height: 29px;
    }
  }
}

.Toastify__toast-container {
  display: flex;
  width: 546px;
  height: 153px;
  padding: var(--16, 16px) var(--28, 28px);
}

/*.Toastify__toast {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  width: 546px;
  height: 153px;
  padding: var(--16, 16px) var(--28, 28px);
  justify-content: space-between;
  align-items: center;
  background: var(--black-100, #1c1c1c);
}*/
.custom-toast-Container {
  background: var(--black-100, #1c1c1c);
  display: flex;

  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 24px;
}
.Toastify__progress-bar--wrp {
  height: none;
}

.Toastify__toast-theme--light {
  background-color: #1c1c1c;
}
.form-card-wrapper {
  padding: 24px;
  gap: 20px;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.form-card-Title {
  font-size: 14px;
  font-weight: 700;
}

.card-line {
  width: 100%;
  border-bottom: 1px solid #e5ecf6;
}

.deals-wrapper {
  display: flex;
  gap: 5px;
  flex-direction: column;
  div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.grade-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.doughnut-grade-card {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 50px;
  top: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}
.doughnut-card {
  background-color: #e3f5ff;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  width: 100%;
}
.doughnut-card Canvas {
  height: 100% !important;
  width: 100% !important;
}

.deduction-report {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 20px;
  background-color: #e3f5ff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  div {
    display: flex;
    gap: 5px;
    flex-direction: row;
  }
}
