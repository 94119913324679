/** @format */

.container {
  display: grid;
  grid-template-columns: auto 1fr auto; /* Changed to 'auto' to accommodate the dynamic width */
  grid-template-rows: 70px 1fr;
  grid-template-areas:
    'menu header right'
    'menu main right';
  height: 100vh;
  overflow: hidden; /* Prevents scroll during transition */
}

.menu,
.right {
  transition: width 0.3s ease; /* This will animate the width of the sidebars */
  overflow-x: hidden; /* Prevents content from spilling out during collapse */
}

.menu {
  grid-area: menu;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-right: 1px solid rgba(28, 28, 28, 0.1);
  background: #ffffff;

  ul {
    list-style: none;
    font-family: Inter;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 12px 0px;
    li {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-decoration: none;
      height: 36px;
      border-radius: 12px;
      justify-content: center;
      padding: 8px;
      width: 180px;

      &.active {
        background-color: rgba(28, 28, 28, 0.05);
      }

      &:hover {
        background-color: rgba(28, 28, 28, 0.05);
      }
    }
    a {
      text-decoration: none;
      display: flex;
      font-family: Inter;
      flex-direction: row;
      align-items: center;
      color: #1c1c1c;

      span {
        font-size: 12px;
        margin-left: 10px;
      }
    }
  }
}

.title {
  width: 100%;
  color: rgba(28, 28, 28, 0.4);
  margin-top: 15px;
  font-size: 15px;
}

.management {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  height: 36px;
  border-radius: 12px;
  justify-content: center;
  padding: 8px;
  width: 180px;
  cursor: pointer;
  &.active {
    background-color: rgba(28, 28, 28, 0.05);
  }

  &:hover {
    background-color: rgba(28, 28, 28, 0.05);
  }

  span {
    margin-left: 8px;
    margin-right: 8px;
  }
}

#affiliates-list {
  li {
    list-style: none;
    display: flex;
    font-size: 12px;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    height: 36px;
    border-radius: 12px;
    justify-content: center;
    padding: 8px;
    width: 180px;
  }
}
.header {
  grid-area: header;
  background: #ffffff;
  border-bottom: 1px solid rgba(28, 28, 28, 0.1);
  padding: 7px 28px 20px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;

  img {
    cursor: pointer;
  }
}
.right-heading-div {
  display: flex;
  justify-content: center;
  font-size: 14px;
  gap: 16px;
}
.left-heading-div {
  ///last div
  display: flex;
  justify-content: center;
  gap: 16px;
  /* width: 200px; */
  align-items: center;
}

.custom-unchecked-switch .ant-switch {
  background-color: #752982 !important; /* Change to your desired color */
}

.custom-unchecked-switch .ant-switch-checked {
  background-color: #752982 !important; /* Keep the checked state color */
}
.date-filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  :nth-child(1) {
    margin-left: 5px;
  }
}
.main {
  grid-area: main;
  background: #ffffff;
  padding: 28px;
  overflow-y: auto; // Scrollbar appears only if necessary
  overflow-x: hidden; // Prevent horizontal scrolling
  max-height: 100vh; // Adjust this value as needed
  border-top: 1px solid rgba(28, 28, 28, 0.1);
}

.right {
  grid-area: right;
  background: #ffffff;

  display: flex;
  padding: var(--16, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  border-left: 1px solid rgba(28, 28, 28, 0.1);
  /* Rest of your styles */
}

.notification-container {
  display: flex;
  height: 228px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: var(--12, 12px);
  align-self: stretch;
  background: linear-gradient(180deg, #fff 87%, #f8f8f8 100%);
}
.asd-leaderboard-container {
  width: 100%;
  height: 100%;
}

button {
  padding: 10px;
  cursor: pointer;
}

/* Optional: Add some hover effect for buttons */
button:hover {
  background-color: #555;
  color: white;
}

.setting-link {
  flex-direction: row;

  display: flex;
  align-items: center;
  cursor: pointer;
}
