/** @format */
.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 28px;

  overflow: hidden;
}
.rates {
  display: flex;

  width: 100%;
}
.pr-pl-rr-sp-wrp {
  display: flex;
  width: 65%;
  height: 100%;
  gap: 28px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  > div {
    display: flex;
    flex-direction: row;
    gap: 28px;
    width: 100%;
  }
}

.rates-blue {
  display: flex !important;

  width: 100%;
  padding: var(--24, 24px);
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;
  gap: var(--8, 20px);

  border-radius: var(--16, 16px);
  background: var(--Primary-Blue, #e3f5ff);
}

.rates-light {
  display: flex !important;

  width: 100%;
  padding: var(--24, 24px);
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  border-radius: var(--16, 16px);
  background: var(--primary-purple-50, rgba(229, 236, 246, 0.5));
}
.l-s-cr-wrp {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 28%;
  margin-left: 82px;
  > div:nth-child(2) {
    background: var(--Secondary-Yellow, #ffe999);
  }

  > div:nth-child(3) {
    background: var(--Secondary-Mint, #baedbd);
  }
}

.rates-purple {
  display: flex;

  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--8, 20px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--16, 16px);
  background: var(--Secondary-Purple, #c6c7f8);
}
.stats-title {
  display: flex;
  gap: 6px;
  span {
    font-size: 12px;
  }
}
.stats {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  span {
    font-size: 12px;
    display: flex;
    gap: 5px;
  }
}
.l-vs-c-r-container {
  display: flex;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  min-width: 100%;
  span {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  height: 360px;

  canvas {
    max-width: 100%;
    height: 234px !important; // Important to maintain aspect ratio
  }
}

/////////goal score
.gl-scr-container {
  display: flex;
  height: 400px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  :nth-child(3) {
    display: flex;
    width: 100%;
    flex-direction: column;

    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }
  canvas {
    height: 300px !important;
    width: 300px !important;
  }
}

.pie-container {
  width: 100%;
  height: 400px;
  display: flex;
  gap: 28px;
}

.fl-pie-container {
  background: var(--Primary-Light, #f7f9fb);
  border-radius: var(--16, 16px);
  display: flex;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 922px;

  Canvas {
    height: 500px !important;
    width: 500px !important;
  }
}

.chnl-container {
  gap: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.export {
  text-decoration: none;
  display: flex;
  gap: 6px;
  align-items: center;
  span {
    color: #1c1c1c;
    font-size: 14px;
  }
}
///////////////////////////////////////////////// tables
.campaign-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders and padding are handled nicely */

  thead {
    color: rgba(28, 28, 28, 0.4);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    border-bottom: 1px solid rgba(28, 28, 28, 0.2);
  }

  thead th {
    padding: 8px 12px; /* Apply padding to table headers */
    text-align: left;
    height: 40px;
  }

  tbody tr {
    min-height: 40px; /* Ensures minimum height for table rows */
    border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
  }

  tbody td {
    padding: 12px 12px; /* Apply padding to table data cells */
    font-size: 12px;
    min-height: 40px;
    font-style: normal;
    font-weight: 400;
    color: var(--black-100, #1c1c1c);
  }
  button {
    color: #ffffff;
    background-color: #1c1c1c;
    border: none;
    border-radius: 5px;
  }
}

.top-5-campaign-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px;
  gap: 4px;
  border-radius: 16px;
  background: #f7f9fb;
  span {
    font-size: 14px;
    font-weight: 600;

    padding-left: 9px;
    margin-bottom: 5px;
  }
}

.monthly-bar-legend {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding-left: 20px;
  span {
    font-size: 12px;
    color: var(--black-40, #1c1c1c66);
    font-weight: 400;
  }
  div {
    display: flex;
    gap: 5px;
  }
}
.lead-indicator {
  width: 14px;
  height: 14px;
  border-radius: var(--Radius-8, 8px);
  background: #e3e8fb;
}
.conversion-indicator {
  width: 14px;
  height: 14px;
  border-radius: var(--Radius-8, 8px);
  background: #95a4fc;
}

.bar-container {
  border-radius: var(--16, 16px);
  padding: 10px;
  background: var(--Primary-Light, #f7f9fb);
  canvas {
    height: 890px;
  }
}
