/** @format */

.affilliates-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--48, 48px);
}

.a-p-tab-wrapper {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  font-weight: 400;
  gap: 23px;

  div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
  }

  select {
    height: 28px;
    border-radius: var(--8, 8px);
    border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
    padding: 5px 20px;
  }
}

.stats-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  width: 100%;
}

.stats-header {
  display: flex;

  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.stats-tab-wrapper {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  gap: 12px;
  color: rgba(28, 28, 28, 0.4);
  span {
    cursor: pointer;
    &.active {
      border-bottom: 2px solid black;
      color: rgb(28, 28, 28);
    }
    &:hover {
      border-bottom: 2px solid black;
    }
  }
}

.add-Agent-Wrapper {
  button {
    display: flex;
    border: none;
    align-items: center;
    gap: 4px;
    background-color: #ffff;
    color: #000000;
    font-size: 14px;
    font-weight: 400;
  }
}
/////////////////////line chart//

.premium-chart-container {
  display: flex;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  min-width: 100%;
  max-height: 592px;

  canvas {
    max-width: 100%;
    height: auto !important; // Important to maintain aspect ratio
  }
}

.premiumm-chart-header {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 16px var(--16, 16px);
  flex-wrap: wrap;
  .heading {
    font-size: 14px;
    font-weight: 600;
  }
}
.legend-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 2px 8px 4px 2px;

  :nth-child(2) {
    display: flex;
    gap: 6px;
    span {
      font-size: 12px;
    }
  }
}
.current-week {
  width: 6px;
  height: 6px;
  background: #95a4fc;
  border-radius: 3px;
}

.previous-week {
  width: 6px;
  height: 6px;
  background: #a1e3cb;
  border-radius: 3px;
}
