/** @format */

.office-profile-container {
  position: absolute;
  width: 100vw;
  height: 105vh;
  top: 0;
  left: 0;
  background-color: rgba(28, 28, 28, 0.2);
}

.profile-wrapper {
  float: right;
  display: flex;
  width: 576px;
  height: 100%;
  padding: var(--16, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex-shrink: 0;
  z-index: 1;
  border-left: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: #fff;
}

.logo-profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  :nth-child(2) {
    width: 345px;
    height: 126px;
    flex-shrink: 0;
  }

  :nth-child(1) {
    width: 100%;

    img {
      float: right;
      width: 20px;
    }
  }
}

.office-pf-info-wrp {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  align-self: stretch;
  width: 100%;
  //////////first div where the offine name and phone number and adresses are
  .pf-sectn-2 {
    font-size: 12px;
    font-weight: 400;

    color: rgba(28, 28, 28, 0.4);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    /////////////////////this is for all the logos that are the same
    div {
      display: flex;
      flex-direction: row;
      gap: 4px;
    }

    align-self: stretch;

    :nth-child(1) {
      font-size: 18px;
      font-weight: 600;
      color: #1c1c1c;
    }
  }
  ////////////////////// this is the progress bar section
  .pf-goal-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;
    span {
      font-size: 14px;
      font-weight: 400;
      color: #1c1c1c;
      margin-bottom: 10px;
    }
  }
}

.profile-stats-table {
  width: 100%;
  th {
    border-top: none;
    font-size: 14px;
    font-weight: 400;
    border-right: 1px solid rgba(28, 28, 28, 0.1);
    padding: 5px;
  }

  td {
    border-left: none;
    border-top: none;
    border-bottom: none;
    text-align: center;
    border-right: 1px solid rgba(28, 28, 28, 0.1);
    padding: 5px;
    font-size: 18px;
    font-weight: 600;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
}

.chart-container {
  display: flex;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  min-width: 544px;
  max-width: 544px;
  height: 193px;

  span {
    font-size: 12px;
    font-weight: 600;
  }
}

.aflte-pfrm-link-wpr {
  width: 100%;
  margin-top: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--16, 16px);
  background: var(--Primary-Blue, #e3f5ff);
  div {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
}

.agent-pfrm-link-wpr {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--16, 16px);
  background: #e5ecf6;
  div {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
}
