/** @format */

.user-header {
  display: flex;
  padding: 0px var(--8, 8px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;

  margin-bottom: 28px;
}
.user-tb-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 2px;
  border-bottom: 2px solid black;
}

.view-profile {
  padding: var(--8, 8px);
  width: 30px;
  height: 30px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: var(--primary-purple-50, rgba(229, 236, 246, 0.5));
  }
}
