/** @format */

.menu-container {
  grid-area: menu;
  padding: 12px;
  border-right: 1px solid rgba(28, 28, 28, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #ffffff;
  transition: width 0.3s ease; /* This will animate the width of the sidebars */
  overflow-x: hidden; /* Prevents content from spilling out during collapse */
}

.menu-list {
  display: flex;
  font-size: 12px;
  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  li {
    list-style: none;
  }

  a {
    color: black;
  }
}
.menu-title {
  color: rgba(28, 28, 28, 0.4);
  /* margin-top: 15px; */
  font-size: 14px;
}

.inactive {
  display: none;
}

.nav-link {
  display: flex;
  gap: 8px;
  padding: 8px;
  align-items: center;
  transition: all 0.2s linear;
  &:hover {
    border-radius: var(--12, 12px);
    background: var(--black-5, rgba(28, 28, 28, 0.05));
    gap: 12px;
  }
}

.active-link {
  border-radius: var(--12, 12px);
  background: var(--black-5, rgba(28, 28, 28, 0.05));
}
