/**
 * ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * /// ////////////////////////////////////////////////////// add company form//////////////////////////////////////////////////////
 *
 * @format
 */

.form-layout {
  width: 100vw;
  padding: 20px;
  /* padding-top: 62px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  gap: 28px;
  background: linear-gradient(
    180deg,
    rgba(215, 208, 255, 0.2),
    rgba(203, 221, 255, 0.5)
  );
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  justify-content: center;

  .form-group {
    margin-bottom: 20px;

    width: 300px;

    label {
      display: block;
      margin-bottom: 5px;
    }
  }
  .input {
    width: 100%;
    height: 45px;
    padding: 10px;
    box-sizing: border-box;
    border-radius: var(--12, 12px);
    border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
    background-color: rgba(255, 255, 255, 0.8);
  }
}
.exit-form {
  display: flex;
  width: var(--40, 40px);
  height: var(--40, 40px);
  padding: var(--8, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
  border-radius: var(--12, 12px);
  background-color: var(--black-5, rgba(28, 28, 28, 0.05));
  border: none;
  cursor: pointer;
}

.exit-form-wrapper {
  width: 680px;
  float: right;
  height: 50px;
  z-index: 20;
}
.form-container {
  display: flex;
  width: 680px;
  height: 704px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--28, 28px);
  background-color: #fff;

  border-radius: 20px;
}

.form-wrapper {
  display: flex;
  width: 680px;
  height: 704px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;

  .input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 28px;
    width: 60%;
    label {
      display: flex;
      flex-direction: column;

      gap: 10px;
    }
  }
}

.upload-wrapper {
  display: flex;
  width: 680px;
  height: 704px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
}
.help-msg-wrapper {
  font-size: 14px;
  color: var(--black-40, rgba(28, 28, 28, 0.4));
  font-weight: 400;
}
.file-wrapper {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 18px;
  :nth-child(1) {
    font-size: 14px;
    font-weight: 600;
  }
  :nth-child(3) {
    text-align: center;
  }
}
.choice-button {
  display: flex;

  min-width: 313px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 16px var(--16, 16px);
  flex-wrap: wrap;

  :nth-child(2) {
    color: #fff;
    background-color: #000000;
  }
  button {
    display: flex;
    padding: var(--8, 8px) var(--16, 16px);
    justify-content: center;
    align-items: center;
    gap: var(--8, 8px);
    flex: 1 0 0;
    border-radius: 12px;
    border: none;
    height: 40px;
  }
  span {
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
  }
}
.message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}

.success {
  background-color: #4caf50; /* Green for success messages */
}

.error {
  background-color: #f44336; /* Red for error messages */
}

.active {
  display: block;
}

.inactive {
  display: none;
}

.name-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  input {
    width: 48% !important;
  }
}

.aor-and-role-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  select {
    height: 28px;
    border-radius: var(--8, 8px);
    border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
    padding: 5px 20px;
  }
}
/*
this is the styling section for the form page
*/

.form-page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--28, 28px);
}

.form-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  align-items: center;
}

.f-p-t {
  font-size: 14px;
  color: #1c1c1c;
  padding-bottom: 2px;
  border-bottom: 2px solid #1c1c1c;
}

.admin-form-tab-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  div {
    span {
      font-size: 14px;
      color: #1c1c1c66;
      cursor: pointer;
      &:hover {
        border-bottom: 2px solid #1c1c1c;
        color: #1c1c1c;
      }
    }
  }
}
.table-active {
  border-bottom: 2px solid #1c1c1c !important;
  color: #1c1c1c !important;
}
/* styles.css */
.table-scroll {
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}
.data-table-container {
  button {
    padding: 6px;
    cursor: pointer;
    border: none;
    width: 64px;
    font-size: 12px;
    border-radius: 20px;
    background-color: black;
    color: white;
  }
}

.upload-wrapper {
  display: flex;
  width: 680px;
  height: 704px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 38px;
}
.upload-Button {
  display: flex;
  padding: var(--16, 16px) var(--24, 24px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
  color: #fff;
  width: 344px;
  border-radius: var(--16, 16px);
  background: var(--Primary-Brand, #1c1c1c);
  cursor: pointer;
}

.aca-bundle-forms {
  display: flex;
  flex-direction: column;
  gap: 28px;
  > div {
    background-color: #e3f5ff;
  }
}

.status-cancel {
  color: white;
  background-color: red;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.status-declined {
  color: black;
  background-color: yellow;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.status-paid {
  color: white;
  background-color: green;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.dependents-section {
  display: flex;
  gap: 34px;
  flex-direction: column;
  button {
    width: 40px !important;
    height: 40px !important;
    background-color: rgb(247 249 251) !important;
    border-radius: 14px !important;
  }
}
.dependent {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.form-link {
  display: flex;
  gap: 20px;

  div {
    &:hover {
      background-color: #e3f5ff;
    }
  }
}

.submission-form-container {
  display: flex;
  gap: 28px;

  flex-direction: column;
}

.form-section {
  background: var(--Primary-Light, #f7f9fb);
  border-radius: 16px;
  padding: 30px var(--24, 24px) var(--24, 24px) var(--24, 24px);

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
}

.member-info {
  input {
    display: flex;
    max-width: 350px;
    min-width: 200px;
    margin-bottom: 16px;
    margin-top: 10px;
    height: 40px;
    border-radius: var(--12, 5px);
    padding: var(--4, 4px) var(--12, 12px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--4, 4px);
    border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
    background: var(--white-80, rgba(255, 255, 255, 0.8));
  }
}
.radio-option {
  display: flex;
  align-items: center;
  gap: 7px;
  input {
    cursor: pointer;
  }
  label {
    cursor: pointer;
  }
  &:hover {
    color: rgb(117, 41, 130);
  }
}

.nb-radial-option-wrapper {
  display: flex;
  width: 100%;
  border-radius: var(--12, 12px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
  padding: 12px 15px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  font-style: normal;
}

.submit-submission-form {
  height: 100%;
  width: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  Button {
    background-color: rgb(117, 41, 130);

    border: none;
    color: #fff;
  }
}

.form-details-container {
  max-height: 80vh;
  overflow-y: auto;
  width: 100%;

  padding: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  background-color: #ffffff;
}

.enable-form-button {
  border: none;
}
.form-details-section {
  margin-bottom: 24px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  font-weight: bold;
}

.white-back-button {
  display: flex;
  padding: var(--4, 4px) var(--8, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--4, 4px);
  border-radius: var(--8, 8px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background-color: #ffffff;
  transition: gap 0.5s, padding-right 0.5s, background-color 0.5s;
  &:hover {
    background-color: #1c1c1c1a;
    gap: 8px;
    padding-right: 8px;
    span {
      color: #000000;
    }
  }
}

.form-title {
  border-radius: 16px;
  background: var(--Brand-Light-Primary, #752982);
  padding: 24px var(--24, 24px) var(--24, 24px) var(--24, 24px);
  span {
    font-size: 14px;
    color: #ffffff;
    font-weight: 600;
  }
}

.form-heading {
  border-radius: 16px;
  width: 100%;
  padding: 24px var(--24, 24px) var(--24, 24px) var(--24, 24px);
  background: var(--Primary-Purple, #e5ecf6);
  span {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
  }
}

.form-input-wrapper {
  display: flex;
  padding: 24px; /* Remove or adjust padding */
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex: 1 0 0;
  border-radius: 16px;
  background: var(--Primary-Light, #f7f9fb);
  label {
    font-size: 14px;
    color: #000000;
    font-weight: 600;
  }
  input {
    width: 100%;
    padding: var(--4, 4px) var(--12, 12px);
    height: 40px;
    border-radius: var(--12, 12px);
    border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
    background: var(--white-80, rgba(255, 255, 255, 0.8));
  }
}

.remove-ancillary-container {
  display: flex;
  padding: 24px var(--24, 24px) var(--24, 24px) var(--24, 24px);

  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Primary-Blue, #e3f5ff);

  button {
    display: flex;
    padding: var(--4, 4px) var(--8, 8px);
    justify-content: center;
    border: none;
    align-items: center;
    gap: var(--4, 4px);
    border-radius: var(--8, 8px);
    background: var(--white-100, #fff);
    color: #000000;
    font-size: 14px;
    transition: gap 0.5s, padding-right 0.5s, background-color 0.5s;
    &:hover {
      background-color: #dc5252;
      gap: 8px;
      padding-right: 8px;
      span {
        color: #000000;
      }
    }
  }
}
.add-ancillary-button {
  display: flex;
  font-weight: 400;
  padding: var(--4, 4px) var(--8, 8px);
  justify-content: center;
  border: none;
  align-items: center;
  gap: var(--4, 4px);
  border-radius: var(--8, 8px);
  background: var(--white-100, #fff);
  color: #000000;
  font-size: 14px;
  transition: gap 0.5s, padding-right 0.5s, background-color 0.5s;
  &:hover {
    background-color: rgb(117, 41, 130);
    gap: 8px;
    padding-right: 8px;
    span {
      color: #ffffff;
    }
  }
}

.customer-details-container {
  height: 102vh;

  width: 102%;
  position: absolute;
  padding: 16px;

  background-color: #ffffff;
  z-index: 3;
  top: 0;
  left: -10px;
}

.custo-input {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
