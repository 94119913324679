/** @format */
html {
  font-family: "Inter", sans-serif;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/** @format */
.signin-container {
  width: 100vw;
  height: 100vh;
  background-color: #f7f9fb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.logo-wrapper img {
  width: 592px;
  height: 133px;
  flex-shrink: 0;
}

.signin-form-container {
  display: flex;
  width: 560px;
  height: 700px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  gap: 28px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}

.signin-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  width: 384px;
}
.signin-form-wrapper input {
  height: 56px;
  border-radius: 16px;
  background: rgba(229, 236, 246, 0.5);
  border: none;
  font-size: 18px;
  width: 100%;
  padding: 10px;
}
.signin-form-wrapper input:focus {
  outline-color: black;
}
.signin-form-wrapper Button {
  width: 100%;
  border-radius: 16px;
  background-color: rgb(0, 0, 0);
  color: #ffffff;
  font-size: 18px;
  padding: 16px 24px;
}

.update-formcontainer {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 162px 380px;
  justify-content: center;
  align-items: center;
  background: #f7f9fb;
}

.update-password-form {
  display: flex;
  border-radius: var(--Radius-32, 32px);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), rgba(255, 255, 255, 0.8);
  /* BG blur 40 */
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  height: 700px;
  width: 680px;
  padding: var(--24, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
}
.update-password-form button {
  margin-top: 28px;
}
.update-password-form h2 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}
.update-password-form label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}
.update-password-form p {
  color: var(--black-40, rgba(28, 28, 28, 0.4));
  font-feature-settings: "cv11" on, "cv01" on, "ss01" on;
  /* 14 Regular */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.update-password-form input[type=password] {
  width: 100%;
  padding: 10px;
  height: 56px;
  border: none;
  margin-bottom: 10px;
  border-radius: 4px;
  border-radius: var(--16, 16px);
  background: var(--primary-purple-50, rgba(229, 236, 246, 0.5));
}
.update-password-form .strength-bar {
  height: 10px;
  border-radius: 5px;
  background-color: #ddd;
  margin-bottom: 8px;
}
.update-password-form .strength-bar.strength-0 {
  width: 10%;
  background-color: #ff4545;
}
.update-password-form .strength-bar.strength-1 {
  width: 25%;
  background-color: #ff6a45;
}
.update-password-form .strength-bar.strength-2 {
  width: 50%;
  background-color: #ffd245;
}
.update-password-form .strength-bar.strength-3 {
  width: 75%;
  background-color: #bff845;
}
.update-password-form .strength-bar.strength-4 {
  width: 100%;
  background-color: #45e645;
}
.update-password-form button {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  border-radius: var(--16, 16px);
  background: var(--Primary-Brand, #1c1c1c);
  cursor: pointer;
}
.update-password-form button:hover {
  background-color: #0056b3;
}

/** @format */
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px;
  padding-left: 40px;
  font-family: "Inter", "Arial", "Sans-serif" !important;
  background-color: #ffffff;
  margin-bottom: 30px;
  height: 88px;
  align-items: center;
}

.header-nav {
  float: right;
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.setting-wrapper {
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 30px;
  border-radius: 20px;
  background: rgba(195, 166, 197, 0.2);
  flex-shrink: 0;
  align-items: center;
  justify-content: space-evenly;
}

.setting-icon {
  width: 19.649px;
  height: 20.003px;
}

.profile-icon {
  width: 20px;
  height: 30px;
  flex-shrink: 0;
}

#setting-link {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebar1 {
  width: 250px;
  height: 100vh;
  background-color: #333;
  color: white;
}
.sidebar1 .menu-item1 {
  padding: 20px;
  cursor: pointer;
}
.sidebar1 .menu-item1:hover {
  background-color: #555;
}
.sidebar1 .menu-item1 .submenu1 {
  display: block;
}
.sidebar1 .menu-item1 .submenu1 .submenu-item1 {
  padding: 10px 20px;
  cursor: pointer;
}
.sidebar1 .menu-item1 .submenu1 .submenu-item1:hover {
  background-color: #777;
}

/** @format */
.content-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.add-button {
  width: 140px;
  height: 40px;
  border-radius: 26px;
  background-color: #400c41;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  border: none;
}

.officeCard {
  display: flex;
  min-width: 240px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex: 1 0 0;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
}

.add-offices-wrapper {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  font-family: "Inter", "Arial", "Sans-serif" !important;
  margin: auto;
  padding: 20px;
  margin-top: 10%;
  background-color: #ffffff;
  border-radius: 20px;
  width: 884px;
  height: 540px;
}
.add-offices-wrapper .exit-button {
  width: 100%;
  margin-bottom: 30px;
}
.add-offices-wrapper .exit-button img {
  float: right;
}

.office-tab-container {
  display: flex;
  padding: 0px 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.office-type-container {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  gap: 12px;
}
.office-type-container span {
  cursor: pointer;
}
.office-type-container span.active {
  border-bottom: 2px solid black;
}
.office-type-container span:hover {
  border-bottom: 2px solid black;
}

.add-office {
  font-weight: 300;
  cursor: pointer;
}

/**
 * ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
 * /// ////////////////////////////////////////////////////// add company form//////////////////////////////////////////////////////
 *
 * @format
 */
.form-layout {
  width: 100vw;
  padding: 20px;
  /* padding-top: 62px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  gap: 28px;
  background: linear-gradient(180deg, rgba(215, 208, 255, 0.2), rgba(203, 221, 255, 0.5));
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
}
.form-layout .form-group {
  margin-bottom: 20px;
  width: 300px;
}
.form-layout .form-group label {
  display: block;
  margin-bottom: 5px;
}
.form-layout .input {
  width: 100%;
  height: 45px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: var(--12, 12px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background-color: rgba(255, 255, 255, 0.8);
}

.exit-form {
  display: flex;
  width: var(--40, 40px);
  height: var(--40, 40px);
  padding: var(--8, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
  border-radius: var(--12, 12px);
  background-color: var(--black-5, rgba(28, 28, 28, 0.05));
  border: none;
  cursor: pointer;
}

.exit-form-wrapper {
  width: 680px;
  float: right;
  height: 50px;
  z-index: 20;
}

.form-container {
  display: flex;
  width: 680px;
  height: 704px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--28, 28px);
  background-color: #fff;
  border-radius: 20px;
}

.form-wrapper {
  display: flex;
  width: 680px;
  height: 704px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 28px;
}
.form-wrapper .input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 60%;
}
.form-wrapper .input-wrapper label {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.upload-wrapper {
  display: flex;
  width: 680px;
  height: 704px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
}

.help-msg-wrapper {
  font-size: 14px;
  color: var(--black-40, rgba(28, 28, 28, 0.4));
  font-weight: 400;
}

.file-wrapper {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 18px;
}
.file-wrapper :nth-child(1) {
  font-size: 14px;
  font-weight: 600;
}
.file-wrapper :nth-child(3) {
  text-align: center;
}

.choice-button {
  display: flex;
  min-width: 313px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 16px var(--16, 16px);
  flex-wrap: wrap;
}
.choice-button :nth-child(2) {
  color: #fff;
  background-color: #000000;
}
.choice-button button {
  display: flex;
  padding: var(--8, 8px) var(--16, 16px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
  flex: 1 0 0;
  border-radius: 12px;
  border: none;
  height: 40px;
}
.choice-button span {
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
}

.message {
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
  color: #fff;
}

.success {
  background-color: #4caf50; /* Green for success messages */
}

.error {
  background-color: #f44336; /* Red for error messages */
}

.active {
  display: block;
}

.inactive {
  display: none;
}

.name-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.name-wrapper input {
  width: 48% !important;
}

.aor-and-role-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.aor-and-role-wrapper select {
  height: 28px;
  border-radius: var(--8, 8px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  padding: 5px 20px;
}

/*
this is the styling section for the form page
*/
.form-page-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--28, 28px);
}

.form-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px;
  width: 100%;
  align-items: center;
}

.f-p-t {
  font-size: 14px;
  color: #1c1c1c;
  padding-bottom: 2px;
  border-bottom: 2px solid #1c1c1c;
}

.admin-form-tab-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}
.admin-form-tab-wrapper div span {
  font-size: 14px;
  color: rgba(28, 28, 28, 0.4);
  cursor: pointer;
}
.admin-form-tab-wrapper div span:hover {
  border-bottom: 2px solid #1c1c1c;
  color: #1c1c1c;
}

.table-active {
  border-bottom: 2px solid #1c1c1c !important;
  color: #1c1c1c !important;
}

/* styles.css */
.table-scroll {
  overflow-x: auto;
  display: block;
  white-space: nowrap;
}

.data-table-container button {
  padding: 6px;
  cursor: pointer;
  border: none;
  width: 64px;
  font-size: 12px;
  border-radius: 20px;
  background-color: black;
  color: white;
}

.upload-wrapper {
  display: flex;
  width: 680px;
  height: 704px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 38px;
}

.upload-Button {
  display: flex;
  padding: var(--16, 16px) var(--24, 24px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
  color: #fff;
  width: 344px;
  border-radius: var(--16, 16px);
  background: var(--Primary-Brand, #1c1c1c);
  cursor: pointer;
}

.aca-bundle-forms {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
.aca-bundle-forms > div {
  background-color: #e3f5ff;
}

.status-cancel {
  color: white;
  background-color: red;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.status-declined {
  color: black;
  background-color: yellow;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.status-paid {
  color: white;
  background-color: green;
  padding: 3px 10px 3px 10px;
  border-radius: 5px;
}

.dependents-section {
  display: flex;
  gap: 34px;
  flex-direction: column;
}
.dependents-section button {
  width: 40px !important;
  height: 40px !important;
  background-color: rgb(247, 249, 251) !important;
  border-radius: 14px !important;
}

.dependent {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.form-link {
  display: flex;
  gap: 20px;
}
.form-link div:hover {
  background-color: #e3f5ff;
}

.submission-form-container {
  display: flex;
  gap: 28px;
  flex-direction: column;
}

.form-section {
  background: var(--Primary-Light, #f7f9fb);
  border-radius: 16px;
  padding: 30px var(--24, 24px) var(--24, 24px) var(--24, 24px);
}
.form-section label {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.member-info input {
  display: flex;
  max-width: 350px;
  min-width: 200px;
  margin-bottom: 16px;
  margin-top: 10px;
  height: 40px;
  border-radius: var(--12, 5px);
  padding: var(--4, 4px) var(--12, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--4, 4px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 7px;
}
.radio-option input {
  cursor: pointer;
}
.radio-option label {
  cursor: pointer;
}
.radio-option:hover {
  color: rgb(117, 41, 130);
}

.nb-radial-option-wrapper {
  display: flex;
  width: 100%;
  border-radius: var(--12, 12px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
  padding: 12px 15px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  font-size: 14px;
  font-style: normal;
}

.submit-submission-form {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.submit-submission-form Button {
  background-color: rgb(117, 41, 130);
  border: none;
  color: #fff;
}

.form-details-container {
  max-height: 80vh;
  overflow-y: auto;
  width: 100%;
  padding: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  background-color: #ffffff;
}

.enable-form-button {
  border: none;
}

.form-details-section {
  margin-bottom: 24px;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
  font-weight: bold;
}

.white-back-button {
  display: flex;
  padding: var(--4, 4px) var(--8, 8px);
  justify-content: center;
  align-items: center;
  gap: var(--4, 4px);
  border-radius: var(--8, 8px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background-color: #ffffff;
  transition: gap 0.5s, padding-right 0.5s, background-color 0.5s;
}
.white-back-button:hover {
  background-color: rgba(28, 28, 28, 0.1019607843);
  gap: 8px;
  padding-right: 8px;
}
.white-back-button:hover span {
  color: #000000;
}

.form-title {
  border-radius: 16px;
  background: var(--Brand-Light-Primary, #752982);
  padding: 24px var(--24, 24px) var(--24, 24px) var(--24, 24px);
}
.form-title span {
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
}

.form-heading {
  border-radius: 16px;
  width: 100%;
  padding: 24px var(--24, 24px) var(--24, 24px) var(--24, 24px);
  background: var(--Primary-Purple, #e5ecf6);
}
.form-heading span {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}

.form-input-wrapper {
  display: flex;
  padding: 24px; /* Remove or adjust padding */
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex: 1 0 0;
  border-radius: 16px;
  background: var(--Primary-Light, #f7f9fb);
}
.form-input-wrapper label {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
}
.form-input-wrapper input {
  width: 100%;
  padding: var(--4, 4px) var(--12, 12px);
  height: 40px;
  border-radius: var(--12, 12px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
}

.remove-ancillary-container {
  display: flex;
  padding: 24px var(--24, 24px) var(--24, 24px) var(--24, 24px);
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 16px;
  background: var(--Primary-Blue, #e3f5ff);
}
.remove-ancillary-container button {
  display: flex;
  padding: var(--4, 4px) var(--8, 8px);
  justify-content: center;
  border: none;
  align-items: center;
  gap: var(--4, 4px);
  border-radius: var(--8, 8px);
  background: var(--white-100, #fff);
  color: #000000;
  font-size: 14px;
  transition: gap 0.5s, padding-right 0.5s, background-color 0.5s;
}
.remove-ancillary-container button:hover {
  background-color: #dc5252;
  gap: 8px;
  padding-right: 8px;
}
.remove-ancillary-container button:hover span {
  color: #000000;
}

.add-ancillary-button {
  display: flex;
  font-weight: 400;
  padding: var(--4, 4px) var(--8, 8px);
  justify-content: center;
  border: none;
  align-items: center;
  gap: var(--4, 4px);
  border-radius: var(--8, 8px);
  background: var(--white-100, #fff);
  color: #000000;
  font-size: 14px;
  transition: gap 0.5s, padding-right 0.5s, background-color 0.5s;
}
.add-ancillary-button:hover {
  background-color: rgb(117, 41, 130);
  gap: 8px;
  padding-right: 8px;
}
.add-ancillary-button:hover span {
  color: #ffffff;
}

.customer-details-container {
  height: 102vh;
  width: 102%;
  position: absolute;
  padding: 16px;
  background-color: #ffffff;
  z-index: 3;
  top: 0;
  left: -10px;
}

.custo-input {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/** @format */
.office-cards-container {
  display: flex;
  margin-top: 60px;
  flex-wrap: wrap;
  gap: 24px;
  padding: 25px;
}

.ant-card-body {
  padding: 20px 0 0 0 !important;
}

.office-card {
  box-shadow: 0px 4px 25px -8px rgba(0, 0, 0, 0.25) !important;
  width: 300px;
  margin-right: 20px;
  text-align: center;
}

.card-content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}
.card-content .user-type {
  font-size: 20px;
  font-size: medium;
}

.card-action {
  width: 100%;
  background-color: rgba(22, 119, 255, 0.12);
  height: 60px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  padding-top: 10px;
}
.card-action img {
  cursor: pointer;
}

.card-view-button {
  background-color: rgb(118, 45, 132);
  width: 102px;
  height: 31px;
  padding: 5px;
  margin: auto;
  cursor: pointer;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.card-wrapper {
  display: flex;
  cursor: pointer;
  min-width: 280px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 16px;
  background: #f7f9fb;
}

.company-heading {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  gap: 2px;
}
.company-heading :nth-child(1) {
  font-style: normal;
  font-weight: 600;
  color: black;
}
.company-heading :nth-child(2) {
  color: rgba(28, 28, 28, 0.4);
  font-weight: 400;
}

.card-goal-progress {
  display: flex;
}
.card-goal-progress span {
  color: #8a8cd9;
  font-size: 12px;
  font-weight: 400;
}

.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}
.progress-bar .progress {
  height: 4px;
  background-color: #76a9fa;
  text-align: center;
  color: white;
  line-height: 20px;
  transition: width 0.4s ease;
  background: #a1e3cb;
}
.progress-bar .progress:before {
  content: attr(data-progress);
}

.card-goal-stats {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
}

.card-images {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.card-images :nth-child(2) img {
  width: 77px;
  height: 29px;
}

.Toastify__toast-container {
  display: flex;
  width: 546px;
  height: 153px;
  padding: var(--16, 16px) var(--28, 28px);
}

/*.Toastify__toast {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  width: 546px;
  height: 153px;
  padding: var(--16, 16px) var(--28, 28px);
  justify-content: space-between;
  align-items: center;
  background: var(--black-100, #1c1c1c);
}*/
.custom-toast-Container {
  background: var(--black-100, #1c1c1c);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 24px;
}

.Toastify__progress-bar--wrp {
  height: none;
}

.Toastify__toast-theme--light {
  background-color: #1c1c1c;
}

.form-card-wrapper {
  padding: 24px;
  gap: 20px;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.form-card-Title {
  font-size: 14px;
  font-weight: 700;
}

.card-line {
  width: 100%;
  border-bottom: 1px solid #e5ecf6;
}

.deals-wrapper {
  display: flex;
  gap: 5px;
  flex-direction: column;
}
.deals-wrapper div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.grade-card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

.doughnut-grade-card {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 50px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doughnut-card {
  background-color: #e3f5ff;
  padding: 30px;
  border-radius: 5px;
  position: relative;
  width: 100%;
}

.doughnut-card Canvas {
  height: 100% !important;
  width: 100% !important;
}

.deduction-report {
  width: 100%;
  height: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  padding: 20px;
  background-color: #e3f5ff;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}
.deduction-report div {
  display: flex;
  gap: 5px;
  flex-direction: row;
}

/** @format */
.sales-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin: auto;
  align-self: stretch;
  width: 771px;
}
.sales-form-wrapper button {
  width: 200px;
  border-radius: var(--16, 16px);
  background: var(--Primary-Brand, #1c1c1c);
  color: #ffffff;
  border: none;
}

.input-group {
  display: flex;
  padding: 24px var(--24, 24px) var(--24, 24px) var(--24, 24px);
  border-radius: 16px;
  background: var(--Primary-Light, #f7f9fb);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex: 1 0 0;
}
.input-group label {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.input-group input {
  display: flex;
  width: 370px;
  height: 40px;
  border-radius: var(--12, 12px);
  padding: var(--4, 4px) var(--12, 12px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--4, 4px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
}

.radial-input {
  display: flex;
  padding: 24px var(--24, 24px) var(--24, 24px) var(--24, 24px);
  border-radius: 16px;
  background: var(--Primary-Light, #f7f9fb);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex: 1 0 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.radial-input label {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.password-requirements {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding-left: 10px;
}

.radial-input-wrapper {
  display: flex;
  width: 100%;
  border-radius: var(--12, 12px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
  padding: 12px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.radial-input-wrapper label {
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  align-items: center;
}

.notes {
  width: 100% !important;
  height: 300px !important;
}

.password-reset-container {
  justify-content: center;
  height: 100vh;
  align-items: center;
  background-color: #f7f9fb;
  display: flex;
}

.password-reset-wrapper {
  display: flex;
  width: 680px;
  height: 700px;
  position: relative;
  padding: var(--24, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--24, 24px);
  flex-shrink: 0;
  border-radius: var(--Radius-32, 32px);
  background: linear-gradient(0deg, var(--white-40, rgba(255, 255, 255, 0.4)) 0%, var(--white-40, rgba(255, 255, 255, 0.4)) 100%), var(--white-80, rgba(255, 255, 255, 0.8));
  /* BG blur 40 */
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}

.reset-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 410px;
  gap: 28px;
}
.reset-wrapper input {
  height: 56px;
  padding: var(--16, 16px) var(--20, 20px);
  align-self: stretch;
  border-radius: var(--16, 16px);
  background: var(--primary-purple-50, rgba(229, 236, 246, 0.5));
  border: none;
}
.reset-wrapper button {
  display: flex;
  padding: var(--16, 16px) var(--24, 24px);
  justify-content: center;
  align-items: center;
  gap: var(--8, 8px);
  align-self: stretch;
  border-radius: var(--16, 16px);
  background: var(--Primary-Brand, #1c1c1c);
  color: #ffffff;
}

.reset-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.back-arrow-wrapper {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: var(--12, 12px);
  background: var(--black-5, rgba(28, 28, 28, 0.05));
  cursor: pointer;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 31px;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 500px;
  width: 100%;
}

.close-button {
  font-size: 20px !important;
  cursor: pointer;
  float: right;
  height: 20px !important;
  text-align: center !important;
  padding: 0px !important;
}

.vendor-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.vendor-list li {
  padding: 8px;
  cursor: pointer;
}

.vendor-list li:hover {
  background-color: #f0f0f0;
}

.vendor-Modal {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  cursor: pointer;
}
.vendor-Modal li:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

/** @format */
.container {
  display: grid;
  grid-template-columns: auto 1fr auto; /* Changed to 'auto' to accommodate the dynamic width */
  grid-template-rows: 70px 1fr;
  grid-template-areas: "menu header right" "menu main right";
  height: 100vh;
  overflow: hidden; /* Prevents scroll during transition */
}

.menu,
.right {
  transition: width 0.3s ease; /* This will animate the width of the sidebars */
  overflow-x: hidden; /* Prevents content from spilling out during collapse */
}

.menu {
  grid-area: menu;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-right: 1px solid rgba(28, 28, 28, 0.1);
  background: #ffffff;
}
.menu ul {
  list-style: none;
  font-family: Inter;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px 0px;
}
.menu ul li {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-decoration: none;
  height: 36px;
  border-radius: 12px;
  justify-content: center;
  padding: 8px;
  width: 180px;
}
.menu ul li.active {
  background-color: rgba(28, 28, 28, 0.05);
}
.menu ul li:hover {
  background-color: rgba(28, 28, 28, 0.05);
}
.menu ul a {
  text-decoration: none;
  display: flex;
  font-family: Inter;
  flex-direction: row;
  align-items: center;
  color: #1c1c1c;
}
.menu ul a span {
  font-size: 12px;
  margin-left: 10px;
}

.title {
  width: 100%;
  color: rgba(28, 28, 28, 0.4);
  margin-top: 15px;
  font-size: 15px;
}

.management {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  height: 36px;
  border-radius: 12px;
  justify-content: center;
  padding: 8px;
  width: 180px;
  cursor: pointer;
}
.management.active {
  background-color: rgba(28, 28, 28, 0.05);
}
.management:hover {
  background-color: rgba(28, 28, 28, 0.05);
}
.management span {
  margin-left: 8px;
  margin-right: 8px;
}

#affiliates-list li {
  list-style: none;
  display: flex;
  font-size: 12px;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  height: 36px;
  border-radius: 12px;
  justify-content: center;
  padding: 8px;
  width: 180px;
}

.header {
  grid-area: header;
  background: #ffffff;
  border-bottom: 1px solid rgba(28, 28, 28, 0.1);
  padding: 7px 28px 20px 28px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;
}
.header img {
  cursor: pointer;
}

.right-heading-div {
  display: flex;
  justify-content: center;
  font-size: 14px;
  gap: 16px;
}

.left-heading-div {
  display: flex;
  justify-content: center;
  gap: 16px;
  /* width: 200px; */
  align-items: center;
}

.custom-unchecked-switch .ant-switch {
  background-color: #752982 !important; /* Change to your desired color */
}

.custom-unchecked-switch .ant-switch-checked {
  background-color: #752982 !important; /* Keep the checked state color */
}

.date-filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.date-filter-container :nth-child(1) {
  margin-left: 5px;
}

.main {
  grid-area: main;
  background: #ffffff;
  padding: 28px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100vh;
  border-top: 1px solid rgba(28, 28, 28, 0.1);
}

.right {
  grid-area: right;
  background: #ffffff;
  display: flex;
  padding: var(--16, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex-shrink: 0;
  border-left: 1px solid rgba(28, 28, 28, 0.1);
  /* Rest of your styles */
}

.notification-container {
  display: flex;
  height: 228px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  border-radius: var(--12, 12px);
  align-self: stretch;
  background: linear-gradient(180deg, #fff 87%, #f8f8f8 100%);
}

.asd-leaderboard-container {
  width: 100%;
  height: 100%;
}

button {
  padding: 10px;
  cursor: pointer;
}

/* Optional: Add some hover effect for buttons */
button:hover {
  background-color: #555;
  color: white;
}

.setting-link {
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/** @format */
.office-profile-container {
  position: absolute;
  width: 100vw;
  height: 105vh;
  top: 0;
  left: 0;
  background-color: rgba(28, 28, 28, 0.2);
}

.profile-wrapper {
  float: right;
  display: flex;
  width: 576px;
  height: 100%;
  padding: var(--16, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex-shrink: 0;
  z-index: 1;
  border-left: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: #fff;
}

.logo-profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.logo-profile-wrapper :nth-child(2) {
  width: 345px;
  height: 126px;
  flex-shrink: 0;
}
.logo-profile-wrapper :nth-child(1) {
  width: 100%;
}
.logo-profile-wrapper :nth-child(1) img {
  float: right;
  width: 20px;
}

.office-pf-info-wrp {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  align-self: stretch;
  width: 100%;
}
.office-pf-info-wrp .pf-sectn-2 {
  font-size: 12px;
  font-weight: 400;
  color: rgba(28, 28, 28, 0.4);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.office-pf-info-wrp .pf-sectn-2 div {
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.office-pf-info-wrp .pf-sectn-2 :nth-child(1) {
  font-size: 18px;
  font-weight: 600;
  color: #1c1c1c;
}
.office-pf-info-wrp .pf-goal-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.office-pf-info-wrp .pf-goal-wrapper span {
  font-size: 14px;
  font-weight: 400;
  color: #1c1c1c;
  margin-bottom: 10px;
}

.profile-stats-table {
  width: 100%;
}
.profile-stats-table th {
  border-top: none;
  font-size: 14px;
  font-weight: 400;
  border-right: 1px solid rgba(28, 28, 28, 0.1);
  padding: 5px;
}
.profile-stats-table td {
  border-left: none;
  border-top: none;
  border-bottom: none;
  text-align: center;
  border-right: 1px solid rgba(28, 28, 28, 0.1);
  padding: 5px;
  font-size: 18px;
  font-weight: 600;
}
.profile-stats-table table {
  border-collapse: collapse;
  width: 100%;
}

.chart-container {
  display: flex;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  flex-direction: column;
  gap: 10px;
  padding: 16px;
  min-width: 544px;
  max-width: 544px;
  height: 193px;
}
.chart-container span {
  font-size: 12px;
  font-weight: 600;
}

.aflte-pfrm-link-wpr {
  width: 100%;
  margin-top: 16px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: var(--16, 16px);
  background: var(--Primary-Blue, #e3f5ff);
}
.aflte-pfrm-link-wpr div {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.agent-pfrm-link-wpr {
  display: flex;
  width: 100%;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: var(--16, 16px);
  background: #e5ecf6;
}
.agent-pfrm-link-wpr div {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

/** @format */
.affilliates-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--48, 48px);
}

.a-p-tab-wrapper {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  align-items: center;
  font-weight: 400;
  gap: 23px;
}
.a-p-tab-wrapper div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}
.a-p-tab-wrapper select {
  height: 28px;
  border-radius: var(--8, 8px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  padding: 5px 20px;
}

.stats-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 28px;
  width: 100%;
}

.stats-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.stats-tab-wrapper {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  gap: 12px;
  color: rgba(28, 28, 28, 0.4);
}
.stats-tab-wrapper span {
  cursor: pointer;
}
.stats-tab-wrapper span.active {
  border-bottom: 2px solid black;
  color: rgb(28, 28, 28);
}
.stats-tab-wrapper span:hover {
  border-bottom: 2px solid black;
}

.add-Agent-Wrapper button {
  display: flex;
  border: none;
  align-items: center;
  gap: 4px;
  background-color: white;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
}

.premium-chart-container {
  display: flex;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  min-width: 100%;
  max-height: 592px;
}
.premium-chart-container canvas {
  max-width: 100%;
  height: auto !important;
}

.premiumm-chart-header {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 16px var(--16, 16px);
  flex-wrap: wrap;
}
.premiumm-chart-header .heading {
  font-size: 14px;
  font-weight: 600;
}

.legend-wrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 2px 8px 4px 2px;
}
.legend-wrapper :nth-child(2) {
  display: flex;
  gap: 6px;
}
.legend-wrapper :nth-child(2) span {
  font-size: 12px;
}

.current-week {
  width: 6px;
  height: 6px;
  background: #95a4fc;
  border-radius: 3px;
}

.previous-week {
  width: 6px;
  height: 6px;
  background: #a1e3cb;
  border-radius: 3px;
}

/** @format */
.dashboard-container {
  display: flex;
  flex-direction: column;
  gap: 28px;
  overflow: hidden;
}

.rates {
  display: flex;
  width: 100%;
}

.pr-pl-rr-sp-wrp {
  display: flex;
  width: 65%;
  height: 100%;
  gap: 28px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.pr-pl-rr-sp-wrp > div {
  display: flex;
  flex-direction: row;
  gap: 28px;
  width: 100%;
}

.rates-blue {
  display: flex !important;
  width: 100%;
  padding: var(--24, 24px);
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;
  gap: var(--8, 20px);
  border-radius: var(--16, 16px);
  background: var(--Primary-Blue, #e3f5ff);
}

.rates-light {
  display: flex !important;
  width: 100%;
  padding: var(--24, 24px);
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  border-radius: var(--16, 16px);
  background: var(--primary-purple-50, rgba(229, 236, 246, 0.5));
}

.l-s-cr-wrp {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 28%;
  margin-left: 82px;
}
.l-s-cr-wrp > div:nth-child(2) {
  background: var(--Secondary-Yellow, #ffe999);
}
.l-s-cr-wrp > div:nth-child(3) {
  background: var(--Secondary-Mint, #baedbd);
}

.rates-purple {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--8, 20px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: var(--16, 16px);
  background: var(--Secondary-Purple, #c6c7f8);
}

.stats-title {
  display: flex;
  gap: 6px;
}
.stats-title span {
  font-size: 12px;
}

.stats {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.stats span {
  font-size: 12px;
  display: flex;
  gap: 5px;
}

.l-vs-c-r-container {
  display: flex;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  min-width: 100%;
  height: 360px;
}
.l-vs-c-r-container span {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.l-vs-c-r-container canvas {
  max-width: 100%;
  height: 234px !important;
}

.gl-scr-container {
  display: flex;
  height: 400px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
}
.gl-scr-container :nth-child(3) {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.gl-scr-container :nth-child(3) span {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.gl-scr-container canvas {
  height: 300px !important;
  width: 300px !important;
}

.pie-container {
  width: 100%;
  height: 400px;
  display: flex;
  gap: 28px;
}

.fl-pie-container {
  background: var(--Primary-Light, #f7f9fb);
  border-radius: var(--16, 16px);
  display: flex;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: 922px;
}
.fl-pie-container Canvas {
  height: 500px !important;
  width: 500px !important;
}

.chnl-container {
  gap: 16px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.chnl-container div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.chnl-container div span {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.export {
  text-decoration: none;
  display: flex;
  gap: 6px;
  align-items: center;
}
.export span {
  color: #1c1c1c;
  font-size: 14px;
}

.campaign-table {
  width: 100%;
  border-collapse: collapse; /* Ensures borders and padding are handled nicely */
}
.campaign-table thead {
  color: rgba(28, 28, 28, 0.4);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  border-bottom: 1px solid rgba(28, 28, 28, 0.2);
}
.campaign-table thead th {
  padding: 8px 12px; /* Apply padding to table headers */
  text-align: left;
  height: 40px;
}
.campaign-table tbody tr {
  min-height: 40px; /* Ensures minimum height for table rows */
  border-bottom: 1px solid var(--black-5, rgba(28, 28, 28, 0.05));
}
.campaign-table tbody td {
  padding: 12px 12px; /* Apply padding to table data cells */
  font-size: 12px;
  min-height: 40px;
  font-style: normal;
  font-weight: 400;
  color: var(--black-100, #1c1c1c);
}
.campaign-table button {
  color: #ffffff;
  background-color: #1c1c1c;
  border: none;
  border-radius: 5px;
}

.top-5-campaign-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px;
  gap: 4px;
  border-radius: 16px;
  background: #f7f9fb;
}
.top-5-campaign-container span {
  font-size: 14px;
  font-weight: 600;
  padding-left: 9px;
  margin-bottom: 5px;
}

.monthly-bar-legend {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding-left: 20px;
}
.monthly-bar-legend span {
  font-size: 12px;
  color: var(--black-40, rgba(28, 28, 28, 0.4));
  font-weight: 400;
}
.monthly-bar-legend div {
  display: flex;
  gap: 5px;
}

.lead-indicator {
  width: 14px;
  height: 14px;
  border-radius: var(--Radius-8, 8px);
  background: #e3e8fb;
}

.conversion-indicator {
  width: 14px;
  height: 14px;
  border-radius: var(--Radius-8, 8px);
  background: #95a4fc;
}

.bar-container {
  border-radius: var(--16, 16px);
  padding: 10px;
  background: var(--Primary-Light, #f7f9fb);
}
.bar-container canvas {
  height: 890px;
}

.campaign-header-containger {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.cpgn-title-wrapper {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.cpgn-title-wrapper span {
  padding-bottom: 3px;
  border-bottom: 2px solid black;
}

.edit-switch-wrp {
  display: flex;
  height: 28px;
  padding: var(--16, 16px) var(--20, 20px);
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 8px;
  border-radius: var(--8, 8px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
}

.edit-switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 10px;
}
.edit-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.edit-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 8px;
}
.edit-switch .slider:before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  left: 0px;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}
.edit-switch input:checked + .slider {
  background-color: #752982;
}
.edit-switch input:checked + .slider:before {
  transform: translateX(15px);
}

.filter-container {
  display: flex;
  padding: var(--8, 8px);
  align-items: center;
  justify-content: space-between;
  align-content: center;
  gap: 16px var(--16, 16px);
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: var(--8, 8px);
  background: var(--Primary-Light, #f7f9fb);
  margin-bottom: 20px;
}

.filter {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;
}
.filter span {
  font-size: 12px;
  font-weight: 400;
}

.search {
  border-radius: var(--8, 8px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  height: 28px;
  background: var(--white-40, rgba(255, 255, 255, 0.4));
  display: flex;
  padding: 2px;
  width: 168px;
}
.search img {
  margin-right: 5px;
}
.search input {
  border: none;
  width: 116px;
  align-items: center;
  align-content: center;
  gap: 4px var(--4, 4px);
  flex-wrap: wrap;
}
.search input:focus {
  border: none;
  outline: none;
}

.vendors-form-conainer {
  position: relative;
  display: flex;
  width: 880px;
  padding-top: 40px;
  height: 704px;
  overflow-y: auto; /* Enables vertical scrolling */
  flex-direction: column;
  align-items: center;
  gap: var(--28, 28px);
  background-color: #fff;
  border-radius: 20px;
  margin: auto;
}

.vendors-form-wrapper {
  max-height: 504px; /* Set max-height to make the form scrollable */
  min-height: 504px;
  overflow-y: auto;
  width: 880px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px; /* Enables vertical scrolling */
}

.vendors-input-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.vendors-input-container input {
  height: 30px;
  padding: 6px;
  border-radius: var(--8, 8px);
  border: 1px solid rgba(28, 28, 28, 0.1);
}
.vendors-input-container button {
  height: 30px;
  padding: 6px;
  border: none;
  color: #fff;
  border-radius: var(--8, 8px);
  background-color: black;
}

.vendors-form-button-conainer {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  bottom: 50px;
}
.vendors-form-button-conainer button {
  padding: 6px;
  width: 180px;
  height: 40px;
  border: none;
  color: #fff;
  border-radius: var(--8, 8px);
  background-color: black;
}

.campaign-tab-wrapper {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  color: rgba(28, 28, 28, 0.4);
  gap: 15px;
}
.campaign-tab-wrapper span {
  cursor: pointer;
  padding-bottom: 2px;
}
.campaign-tab-wrapper span:hover {
  border-bottom: 2px solid #1c1c1c;
  color: #1c1c1c;
}
.campaign-tab-wrapper span.active {
  border-bottom: 2px solid black;
  color: rgb(28, 28, 28);
}

/** @format */
.top-three-container {
  display: flex;
  gap: 28px;
  align-items: flex-start;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  justify-content: space-between;
}
.top-three-container::-webkit-scrollbar {
  display: none;
}

.podium {
  display: flex;
  min-width: 400px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex-shrink: 0;
  border-radius: var(--16, 16px);
}

.gold {
  background: var(--Secondary-Yellow, #ffe999);
}

.silver {
  background: var(--Primary-Light, #f7f9fb);
}

.bronze {
  background: rgba(255, 203, 131, 0.6);
}

.pf-l-s-cr-wrapper {
  display: flex;
  padding: var(--16, 16px);
  align-items: center;
  align-content: center;
  gap: 28px var(--28, 28px);
  align-self: stretch;
  flex-direction: row;
  border-radius: var(--16, 16px);
  background: var(--primary-purple-50, rgba(229, 236, 246, 0.5));
}

.place {
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.leaderboard-bar-chart {
  display: flex;
  border-radius: var(--16, 16px);
  /* background: var(--Primary-Light, #f7f9fb); */
  flex-direction: column;
  gap: 10px;
  /* padding: 16px; */
  min-width: 380px;
  max-width: 380px;
  height: 115px;
}

.side-leaderboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
}
.side-leaderboard-container img {
  height: 45px;
}

.place-wrapper {
  display: flex;
  flex-direction: row;
  gap: 17px;
  align-items: center;
}

.leaderboard-pf {
  position: relative;
  width: 45px;
}

.rank {
  position: absolute;
  background-color: #1c1c1c;
  color: #ffffff;
  border-radius: 6px;
  font-size: 12px;
  width: 20px;
  text-align: center;
  height: 20px;
  bottom: 0;
  right: -8px;
}

.table-rank {
  border-radius: 6px;
  font-size: 12px;
  padding: 6px 8px;
  /* text-align: center; */
  margin: auto;
  display: flex;
  width: 25px;
  justify-content: center;
}

.top1 {
  background-color: #ffe999;
  color: #1c1c1c;
}

.top2 {
  background-color: #a8c5da;
  color: #1c1c1c;
}

.top3 {
  background-color: #ffcb83;
  color: #1c1c1c;
}

.rest {
  background-color: #000000;
  color: #ffffff;
}

.side-s-l {
  font-style: normal;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: var(--12, 12px);
  cursor: pointer;
  gap: 4px;
  width: 80%;
  transition: background-color 0.2s ease, color 0.2s ease, font-size 0.2s ease;
}
.side-s-l div {
  color: rgba(28, 28, 28, 0.4);
  font-size: 12px;
  transition: color 0.2s ease, font-size 0.2s ease;
}
.side-s-l div :nth-child(1) {
  padding-right: 5px;
}
.side-s-l span {
  transition: font-weight 0.2s ease, color 0.2s ease;
}
.side-s-l span::first-letter {
  text-transform: uppercase;
}
.side-s-l:hover {
  background-color: rgba(28, 28, 28, 0.0901960784);
}
.side-s-l:hover div {
  color: rgb(28, 28, 28);
  font-size: 14px;
}
.side-s-l:hover span {
  font-weight: 500;
  color: #1c1c1c;
  font-size: 14px;
}

.data-table td {
  text-align: center;
  padding: 8px 12px;
  height: 40px;
  border-bottom: 1px solid rgba(28, 28, 28, 0.05);
  font-size: 12px;
}
.data-table thead {
  color: rgba(28, 28, 28, 0.4);
  font-size: 12px;
  height: 40px;
  font-weight: 400;
  border-bottom: 1px solid rgba(28, 28, 28, 0.2);
}
.data-table th {
  padding: 8px 12px;
}

.five-day-table {
  background-color: #a8c5da;
}
.five-day-table td {
  text-align: center;
  padding: 8px 12px;
  height: 40px;
  border-bottom: 1px solid rgba(28, 28, 28, 0.05);
  font-size: 12px;
  color: #1c1c1c;
  border: 0.5px solid black;
}
.five-day-table thead {
  color: rgb(28, 28, 28);
  font-size: 16px;
  height: 40px;
  font-weight: 400;
  border-bottom: 1px solid rgba(28, 28, 28, 0.2);
}
.five-day-table th {
  padding: 8px 12px;
  border: 0.5px solid black;
}

.leaderboard-tab-wrapper {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  color: rgba(28, 28, 28, 0.4);
  gap: 15px;
}
.leaderboard-tab-wrapper span {
  cursor: pointer;
  padding-bottom: 2px;
}
.leaderboard-tab-wrapper span:hover {
  border-bottom: 2px solid #1c1c1c;
  color: #1c1c1c;
}
.leaderboard-tab-wrapper span.active {
  border-bottom: 2px solid black;
  color: rgb(28, 28, 28);
}

.leaderboard-table .agent-column {
  background-color: #f5f5f5;
  font-weight: bold;
}

.leaderboard-table th:not(:first-child) {
  border: 1.5px solid black;
}

.leaderboard-table .majormed-column {
  background-color: #e6f7ff;
  border: 1px solid #000000;
}

.leaderboard-table .privatemed-column {
  background-color: #fffbe6;
  border: 1px solid #000000;
}

.leaderboard-table .premiums-column {
  background-color: #f6ffed;
  border: 1px solid #000000;
}

.leaderboard-table .total-majormed-column {
  background-color: #bae7ff;
  border: 1px solid #91d5ff;
  font-weight: bold;
}

.leaderboard-table .total-privatemed-column {
  background-color: #fffb8f;
  border: 1px solid #ffe58f;
  font-weight: bold;
}

.leaderboard-table .total-premium-column {
  background-color: #d9f7be;
  border: 1px solid #b7eb8f;
  font-weight: bold;
}

.grid-link-container {
  display: flex;
  padding-right: 29px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.grid-link-container span {
  font-size: 14px;
}

.grid {
  width: 64px;
  height: 64px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.grid-link {
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 2px;
  border-radius: 10%;
  width: 100px;
  align-items: flex-end;
  cursor: pointer;
  transition: all 0.2s ease;
}
.grid-link:hover {
  background-color: rgba(65, 63, 63, 0.1019607843);
  gap: 5px;
}

/** @format */
.user-header {
  display: flex;
  padding: 0px var(--8, 8px);
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  display: flex;
  gap: 10px;
  margin-bottom: 28px;
}

.user-tb-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  padding-bottom: 2px;
  border-bottom: 2px solid black;
}

.view-profile {
  padding: var(--8, 8px);
  width: 30px;
  height: 30px;
  border-radius: 8px;
  cursor: pointer;
}
.view-profile:hover {
  background: var(--primary-purple-50, rgba(229, 236, 246, 0.5));
}

.sale-submission-form-container {
  width: 100%;
  display: flex;
}

/** @format */
.user-details-wrapper {
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  padding: 24px;
  width: 80%;
}

.prof-heading {
  font-weight: 600;
  font-style: normal;
}

.detail-wrapper {
  border-radius: var(--16, 16px);
  background: var(--white-80, rgba(255, 255, 255, 0.8));
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.detail-title {
  font-size: 12px;
  font-weight: 400;
  color: var(--black-40, rgba(28, 28, 28, 0.4));
}

.detail-text {
  font-size: 15px;
  color: rgba(28, 28, 28, 0.842);
}

.settings-container {
  width: 680px;
  height: 704px;
  padding-top: 25px;
  position: relative;
  display: inline-block;
  background: var(--Primary-Light, #f7f9fb);
  border-radius: 20px;
}

.profile-pic-large {
  width: 50%;
  border-radius: 50%;
  margin: auto;
}
.profile-pic-large img {
  width: 80%;
  margin: auto;
  display: block;
  border-radius: 50%;
}

.edit-icon {
  position: absolute;
  bottom: 10px; /* Adjust based on your preference */
  right: 32%; /* Adjust based on your preference */
  cursor: pointer;
}

.upload-wrapper {
  height: 96.4%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/** @format */
.sb-profile-container {
  display: flex;
  padding: 28px;
  flex-direction: row;
  align-items: flex-start;
  gap: var(--28, 28px);
  align-self: stretch;
}
.sb-profile-container img.sb-pf-pic {
  height: 125px;
  width: 125px;
  border-radius: 50%;
}

.exit-sb-pf {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}
.exit-sb-pf img {
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.exit-sb-pf span {
  font-size: 14px;
  cursor: pointer;
}

.user-sb-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.user-sb-info div {
  display: flex;
  flex-direction: row;
  gap: 4px;
  color: rgba(28, 28, 28, 0.4);
}
.user-sb-info > div:nth-of-type(1) {
  color: #000000;
  font-size: 18px;
  font-weight: 600;
}

/** @format */
.menu-container {
  grid-area: menu;
  padding: 12px;
  border-right: 1px solid rgba(28, 28, 28, 0.1);
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #ffffff;
  transition: width 0.3s ease; /* This will animate the width of the sidebars */
  overflow-x: hidden; /* Prevents content from spilling out during collapse */
}

.menu-list {
  display: flex;
  font-size: 12px;
}
.menu-list ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
}
.menu-list li {
  list-style: none;
}
.menu-list a {
  color: black;
}

.menu-title {
  color: rgba(28, 28, 28, 0.4);
  /* margin-top: 15px; */
  font-size: 14px;
}

.inactive {
  display: none;
}

.nav-link {
  display: flex;
  gap: 8px;
  padding: 8px;
  align-items: center;
  transition: all 0.2s linear;
}
.nav-link:hover {
  border-radius: var(--12, 12px);
  background: var(--black-5, rgba(28, 28, 28, 0.05));
  gap: 12px;
}

.active-link {
  border-radius: var(--12, 12px);
  background: var(--black-5, rgba(28, 28, 28, 0.05));
}

/** @format *//*# sourceMappingURL=index.css.map */