/** @format */

.content-heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}

.add-button {
  width: 140px;
  height: 40px;
  border-radius: 26px;
  background-color: #400c41;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  border: none;
}
.officeCard {
  display: flex;
  min-width: 240px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex: 1 0 0;
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
}
.add-offices-wrapper {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  font-family: 'Inter', 'Arial', 'Sans-serif' !important;
  margin: auto;
  padding: 20px;
  margin-top: 10%;
  background-color: #ffffff;
  border-radius: 20px;
  width: 884px;
  height: 540px;

  .exit-button {
    width: 100%;
    margin-bottom: 30px;
    img {
      float: right;
    }
  }
}

.office-tab-container {
  display: flex;
  padding: 0px 8px;

  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.office-type-container {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  gap: 12px;

  span {
    cursor: pointer;
    &.active {
      border-bottom: 2px solid black;
    }
    &:hover {
      border-bottom: 2px solid black;
    }
  }
}

.add-office {
  font-weight: 300;
  cursor: pointer;
}
