/** @format */

.signin-container {
  width: 100vw;
  height: 100vh;
  background-color: #f7f9fb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.logo-wrapper {
  img {
    width: 592px;
    height: 133px;
    flex-shrink: 0;
  }
}

.signin-form-container {
  display: flex;
  width: 560px;
  height: 700px;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  gap: 28px;

  // Correct use of background with a linear gradient and a color
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 100%
    ),
    rgba(255, 255, 255, 0.8);

  // Backdrop filter for a blur effect
  backdrop-filter: blur(20px);
}

.signin-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  width: 384px;

  input {
    height: 56px;

    border-radius: 16px;
    background: rgba(229, 236, 246, 0.5);
    border: none;
    font-size: 18px;
    width: 100%;
    padding: 10px;

    &:focus {
      outline-color: black;
    }
  }

  Button {
    width: 100%;
    border-radius: 16px;
    background-color: rgb(0, 0, 0);
    color: #ffffff;
    font-size: 18px;
    padding: 16px 24px;
  }
}

////////////////
.update-formcontainer {
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 162px 380px;
  justify-content: center;
  align-items: center;
  background: #f7f9fb;
}
.update-password-form {
  display: flex;
  border-radius: var(--Radius-32, 32px);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 100%
    ),
    rgba(255, 255, 255, 0.8);
  /* BG blur 40 */
  backdrop-filter: blur(20px);
  height: 700px;
  width: 680px;
  padding: var(--24, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  button {
    margin-top: 28px;
  }

  h2 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }

  label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  p {
    color: var(--black-40, rgba(28, 28, 28, 0.4));
    font-feature-settings: "cv11" on, "cv01" on, "ss01" on;

    /* 14 Regular */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  input[type="password"] {
    width: 100%;
    padding: 10px;
    height: 56px;
    border: none;
    margin-bottom: 10px;

    border-radius: 4px;
    border-radius: var(--16, 16px);
    background: var(--primary-purple-50, rgba(229, 236, 246, 0.5));
  }

  .strength-bar {
    height: 10px;
    border-radius: 5px;
    background-color: #ddd;
    margin-bottom: 8px;

    &.strength-0 {
      width: 10%;
      background-color: #ff4545;
    }
    &.strength-1 {
      width: 25%;
      background-color: #ff6a45;
    }
    &.strength-2 {
      width: 50%;
      background-color: #ffd245;
    }
    &.strength-3 {
      width: 75%;
      background-color: #bff845;
    }
    &.strength-4 {
      width: 100%;
      background-color: #45e645;
    }
  }

  button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    border-radius: var(--16, 16px);
    background: var(--Primary-Brand, #1c1c1c);
    cursor: pointer;

    &:hover {
      background-color: #0056b3;
    }
  }
}
