/** @format */

.top-three-container {
  display: flex;
  gap: 28px;
  align-items: flex-start;
  overflow-x: scroll; // Allows horizontal scrolling
  scrollbar-width: none; // Hides the scrollbar for Firefox
  -ms-overflow-style: none; // Hides the scrollbar for Internet Explorer 10+
  justify-content: space-between;
  &::-webkit-scrollbar {
    display: none; // Hides the scrollbar for Chrome, Safari, and Opera
  }
}

.podium {
  display: flex;

  min-width: 400px;
  padding: var(--24, 24px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex-shrink: 0;
  border-radius: var(--16, 16px);
}
.gold {
  background: var(--Secondary-Yellow, #ffe999);
}

.silver {
  background: var(--Primary-Light, #f7f9fb);
}

.bronze {
  background: rgba(255, 203, 131, 0.6);
}

.pf-l-s-cr-wrapper {
  display: flex;
  padding: var(--16, 16px);
  align-items: center;
  align-content: center;
  gap: 28px var(--28, 28px);
  align-self: stretch;
  flex-direction: row;
  border-radius: var(--16, 16px);
  background: var(--primary-purple-50, rgba(229, 236, 246, 0.5));
}

.place {
  position: absolute;
  bottom: -5px;
  right: -5px;
}

.leaderboard-bar-chart {
  display: flex;
  border-radius: var(--16, 16px);
  /* background: var(--Primary-Light, #f7f9fb); */
  flex-direction: column;
  gap: 10px;
  /* padding: 16px; */
  min-width: 380px;
  max-width: 380px;
  height: 115px;
}

///////////side leaderboard

.side-leaderboard-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  img {
    height: 45px;
  }
}

.place-wrapper {
  display: flex;
  flex-direction: row;
  gap: 17px;

  align-items: center;
}
.leaderboard-pf {
  position: relative;
  width: 45px;
}
.rank {
  position: absolute;
  background-color: #1c1c1c;
  color: #ffffff;
  border-radius: 6px;
  font-size: 12px;
  width: 20px;
  text-align: center;
  height: 20px;
  bottom: 0;
  right: -8px;
}
.table-rank {
  border-radius: 6px;
  font-size: 12px;
  padding: 6px 8px;
  /* text-align: center; */
  margin: auto;
  display: flex;
  width: 25px;
  justify-content: center;
}
.top1 {
  background-color: #ffe999;
  color: #1c1c1c;
}

.top2 {
  background-color: #a8c5da;
  color: #1c1c1c;
}

.top3 {
  background-color: #ffcb83;
  color: #1c1c1c;
}
.rest {
  background-color: #000000;
  color: #ffffff;
}
.side-s-l {
  font-style: normal;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: var(--12, 12px);
  cursor: pointer;
  gap: 4px;
  width: 80%;
  // Apply transition to the default state
  transition: background-color 0.2s ease, color 0.2s ease, font-size 0.2s ease;

  div {
    color: rgba(28, 28, 28, 0.4);
    font-size: 12px;
    transition: color 0.2s ease, font-size 0.2s ease; // Apply transition here
    :nth-child(1) {
      padding-right: 5px;
    }
  }

  span {
    transition: font-weight 0.2s ease, color 0.2s ease; // Apply transition here
    &::first-letter {
      text-transform: uppercase;
    }
  }

  // Hover state changes
  &:hover {
    background-color: #1c1c1c17;

    div {
      color: rgb(28, 28, 28);
      font-size: 14px;
    }
    span {
      font-weight: 500;
      color: #1c1c1c;
      font-size: 14px;
    }
  }
}

.data-table {
  td {
    text-align: center;
    padding: 8px 12px;
    height: 40px;
    border-bottom: 1px solid rgba(28, 28, 28, 0.05);
    font-size: 12px;
  }
  thead {
    color: rgba(28, 28, 28, 0.4);
    font-size: 12px;
    height: 40px;
    font-weight: 400;
    border-bottom: 1px solid rgba(28, 28, 28, 0.2);
  }
  th {
    padding: 8px 12px;
  }
}

.five-day-table {
  background-color: #a8c5da;
  td {
    text-align: center;
    padding: 8px 12px;
    height: 40px;
    border-bottom: 1px solid rgba(28, 28, 28, 0.05);
    font-size: 12px;
    color: #1c1c1c;
    border: 0.5px solid black;
  }
  thead {
    color: rgb(28, 28, 28);
    font-size: 16px;
    height: 40px;
    font-weight: 400;
    border-bottom: 1px solid rgba(28, 28, 28, 0.2);
  }
  th {
    padding: 8px 12px;
    border: 0.5px solid black;
  }
}
.leaderboard-tab-wrapper {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  color: rgba(28, 28, 28, 0.4);
  gap: 15px;
  span {
    cursor: pointer;
    padding-bottom: 2px;
    &:hover {
      border-bottom: 2px solid #1c1c1c;
      color: #1c1c1c;
    }
    &.active {
      border-bottom: 2px solid black;
      color: rgb(28, 28, 28);
    }
  }
}

.leaderboard-table .agent-column {
  background-color: #f5f5f5;
  font-weight: bold;
}
.leaderboard-table {
  th:not(:first-child) {
    border: 1.5px solid black;
  }
}
.leaderboard-table .majormed-column {
  background-color: #e6f7ff;
  border: 1px solid #000000;
}

.leaderboard-table .privatemed-column {
  background-color: #fffbe6;
  border: 1px solid #000000;
}

.leaderboard-table .premiums-column {
  background-color: #f6ffed;
  border: 1px solid #000000;
}

.leaderboard-table .total-majormed-column {
  background-color: #bae7ff;
  border: 1px solid #91d5ff;
  font-weight: bold;
}

.leaderboard-table .total-privatemed-column {
  background-color: #fffb8f;
  border: 1px solid #ffe58f;
  font-weight: bold;
}

.leaderboard-table .total-premium-column {
  background-color: #d9f7be;
  border: 1px solid #b7eb8f;
  font-weight: bold;
}

.grid-link-container {
  display: flex;
  padding-right: 29px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;

  span {
    font-size: 14px;
  }
}

.grid {
  width: 64px;
  height: 64px;
  display: flex;

  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.grid-link {
  display: flex;
  flex-direction: row;
  padding: 8px;
  gap: 2px;
  border-radius: 10%;
  width: 100px;
  align-items: flex-end;
  cursor: pointer;
  transition: all 0.2s ease;
  &:hover {
    background-color: #413f3f1a;
    gap: 5px;
  }
}
