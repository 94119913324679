/** @format */

/////////////////////////////////////////////////////////////////////header nav////////////////////////////////
.header {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  padding: 25px;
  padding-left: 40px;
  font-family: 'Inter', 'Arial', 'Sans-serif' !important;
  background-color: #ffffff;
  margin-bottom: 30px;
  height: 88px;
  align-items: center;
}

.header-nav {
  float: right;
  width: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.setting-wrapper {
  display: flex;
  flex-direction: row;
  width: 90px;
  height: 30px;
  border-radius: 20px;
  background: rgba(195, 166, 197, 0.2);
  flex-shrink: 0;
  align-items: center;
  justify-content: space-evenly;
}

.setting-icon {
  width: 19.649px;
  height: 20.003px;
}

.profile-icon {
  width: 20px;
  height: 30px;
  flex-shrink: 0;
}
/////////////////////////////////////////////////////////////////////sidebar nav////////////////////////////////
#setting-link {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  align-items: center;
  cursor: pointer;
}
