/** @format */

.user-details-wrapper {
  border-radius: var(--16, 16px);
  background: var(--Primary-Light, #f7f9fb);
  padding: 24px;
  width: 80%;
}

.prof-heading {
  font-weight: 600;
  font-style: normal;
}

.detail-wrapper {
  border-radius: var(--16, 16px);
  background: var(--white-80, rgba(255, 255, 255, 0.8));
  padding: 16px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.detail-title {
  font-size: 12px;
  font-weight: 400;
  color: var(--black-40, rgba(28, 28, 28, 0.4));
}

.detail-text {
  font-size: 15px;
  color: rgba(28, 28, 28, 0.842);
}

.settings-container {
  width: 680px;
  height: 704px;
  padding-top: 25px;
  position: relative;
  display: inline-block;
  background: var(--Primary-Light, #f7f9fb);
  border-radius: 20px;
}

.profile-pic-large {
  width: 50%;

  border-radius: 50%;
  margin: auto;

  img {
    width: 80%;
    margin: auto;
    display: block;
    border-radius: 50%;
  }
}

.edit-icon {
  position: absolute;
  bottom: 10px; /* Adjust based on your preference */
  right: 32%; /* Adjust based on your preference */
  cursor: pointer;
}

.upload-wrapper {
  height: 96.4%;
  display: flex;
  justify-content: center;
  align-items: center;
}
