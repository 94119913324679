/** @format */

.sales-form-wrapper {
  display: flex;
  flex-direction: column;

  gap: 28px;
  margin: auto;
  align-self: stretch;
  width: 771px;

  button {
    width: 200px;
    border-radius: var(--16, 16px);
    background: var(--Primary-Brand, #1c1c1c);
    color: #ffffff;
    border: none;
  }
}

.input-group {
  display: flex;
  padding: 24px var(--24, 24px) var(--24, 24px) var(--24, 24px);
  border-radius: 16px;

  background: var(--Primary-Light, #f7f9fb);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex: 1 0 0;
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }
  input {
    display: flex;
    width: 370px;
    height: 40px;
    border-radius: var(--12, 12px);
    padding: var(--4, 4px) var(--12, 12px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--4, 4px);
    border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
    background: var(--white-80, rgba(255, 255, 255, 0.8));
  }
}

.radial-input {
  display: flex;
  padding: 24px var(--24, 24px) var(--24, 24px) var(--24, 24px);
  border-radius: 16px;

  background: var(--Primary-Light, #f7f9fb);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--16, 16px);
  flex: 1 0 0;
  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}
.password-requirements {
  list-style: none;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  padding-left: 10px;
}
.radial-input-wrapper {
  display: flex;
  width: 100%;
  border-radius: var(--12, 12px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
  padding: 12px 15px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  label {
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    align-items: center;
  }
}

.notes {
  width: 100% !important;
  height: 300px !important;
}

.password-reset-container {
  justify-content: center;
  height: 100vh;
  align-items: center;
  background-color: #f7f9fb;
  display: flex;
}

.password-reset-wrapper {
  display: flex;
  width: 680px;
  height: 700px;
  position: relative;
  padding: var(--24, 24px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--24, 24px);
  flex-shrink: 0;
  border-radius: var(--Radius-32, 32px);
  background: linear-gradient(
      0deg,
      var(--white-40, rgba(255, 255, 255, 0.4)) 0%,
      var(--white-40, rgba(255, 255, 255, 0.4)) 100%
    ),
    var(--white-80, rgba(255, 255, 255, 0.8));

  /* BG blur 40 */
  backdrop-filter: blur(20px);
}

.reset-wrapper {
  flex-direction: column;
  align-items: center;
  display: flex;
  width: 410px;
  gap: 28px;

  input {
    height: 56px;
    padding: var(--16, 16px) var(--20, 20px);

    align-self: stretch;
    border-radius: var(--16, 16px);
    background: var(--primary-purple-50, rgba(229, 236, 246, 0.5));
    border: none;
  }

  button {
    display: flex;
    padding: var(--16, 16px) var(--24, 24px);
    justify-content: center;
    align-items: center;
    gap: var(--8, 8px);
    align-self: stretch;
    border-radius: var(--16, 16px);
    background: var(--Primary-Brand, #1c1c1c);
    color: #ffffff;
  }
}

.reset-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.back-arrow-wrapper {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: var(--12, 12px);
  background: var(--black-5, rgba(28, 28, 28, 0.05));
  cursor: pointer;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 31px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 500px;
  width: 100%;
}

.close-button {
  font-size: 20px !important;
  cursor: pointer;
  float: right;
  height: 20px !important;
  text-align: center !important;
  padding: 0px !important;
}

.vendor-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.vendor-list li {
  padding: 8px;
  cursor: pointer;
}

.vendor-list li:hover {
  background-color: #f0f0f0;
}

.vendor-Modal {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  cursor: pointer;
  li:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
