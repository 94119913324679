.campaign-header-containger {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.cpgn-title-wrapper {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  span {
    padding-bottom: 3px;
    border-bottom: 2px solid black;
  }
}

.edit-switch-wrp {
  display: flex;
  height: 28px;
  padding: var(--16, 16px) var(--20, 20px);
  flex-direction: row;
  align-items: center;
  align-content: center;
  gap: 8px;
  border-radius: var(--8, 8px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  background: var(--white-80, rgba(255, 255, 255, 0.8));
}
.edit-switch {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 10px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 8px;

    &:before {
      position: absolute;
      content: "";
      height: 8px;
      width: 8px;
      left: 0px;
      bottom: 1px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  input:checked + .slider {
    background-color: #752982;
  }

  input:checked + .slider:before {
    transform: translateX(15px);
  }
}

.filter-container {
  display: flex;
  padding: var(--8, 8px);
  align-items: center;
  justify-content: space-between;
  align-content: center;
  gap: 16px var(--16, 16px);
  align-self: stretch;
  flex-wrap: wrap;
  border-radius: var(--8, 8px);
  background: var(--Primary-Light, #f7f9fb);
  margin-bottom: 20px;
}

.filter {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 20px;
  span {
    font-size: 12px;
    font-weight: 400;
  }
}

.search {
  border-radius: var(--8, 8px);
  border: 1px solid var(--black-10, rgba(28, 28, 28, 0.1));
  height: 28px;
  background: var(--white-40, rgba(255, 255, 255, 0.4));
  display: flex;
  padding: 2px;
  width: 168px;
  img {
    margin-right: 5px;
  }
  input {
    border: none;
    width: 116px;

    align-items: center;
    align-content: center;
    gap: 4px var(--4, 4px);
    flex-wrap: wrap;

    &:focus {
      border: none;
      outline: none;
    }
  }
}

.vendors-form-conainer {
  position: relative;
  display: flex;
  width: 880px;
  padding-top: 40px;
  height: 704px;

  overflow-y: auto; /* Enables vertical scrolling */
  flex-direction: column;
  align-items: center;
  gap: var(--28, 28px);
  background-color: #fff;
  border-radius: 20px;
  margin: auto;
}
.vendors-form-wrapper {
  max-height: 504px; /* Set max-height to make the form scrollable */
  min-height: 504px;
  overflow-y: auto;
  width: 880px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px; /* Enables vertical scrolling */
}

.vendors-input-container {
  display: flex;
  flex-direction: row;
  gap: 5px;
  input {
    height: 30px;
    padding: 6px;
    border-radius: var(--8, 8px);
    border: 1px solid rgba(28, 28, 28, 0.1);
  }
  button {
    height: 30px;
    padding: 6px;
    border: none;
    color: #fff;
    border-radius: var(--8, 8px);
    background-color: black;
  }
}

.vendors-form-button-conainer {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  bottom: 50px;
  button {
    padding: 6px;
    width: 180px;
    height: 40px;
    border: none;
    color: #fff;
    border-radius: var(--8, 8px);
    background-color: black;
  }
}

.campaign-tab-wrapper {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  color: rgba(28, 28, 28, 0.4);
  gap: 15px;
  span {
    cursor: pointer;
    padding-bottom: 2px;
    &:hover {
      border-bottom: 2px solid #1c1c1c;
      color: #1c1c1c;
    }
    &.active {
      border-bottom: 2px solid black;
      color: rgb(28, 28, 28);
    }
  }
}
