/** @format */
// Define your primary font early in the style sheet
html {
  font-family: 'Inter', sans-serif;
}

body {
  margin: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@import '_signin.scss';
@import '_nav.scss';
@import '_test.scss';
@import '_offices.scss';
@import '_form.scss';
@import './widgets/_card.scss';
@import './widgets/_forms.scss';
@import '_layout.scss';
@import './widgets/office-profile';
@import '_affilliates-Performace';
@import '_stats.scss';
@import '_campaign.scss';
@import '_leaderboard.scss';
@import '_users.scss';
@import '_submitionForm.scss';
@import '_settings.scss';
@import '_userProfile.scss';
@import '_menu.scss';
@import '_library.scss';
